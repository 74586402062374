<style lang="scss">
@import "~@/assets/css/var";

.dev-table-four {
    &__title {
        font-weight: bold;
        margin: 15px;
        font-size: 14px;
        position: sticky;
        top: 0;
        z-index: 10;
        background: #fff;
    }

    .ts-table {
        background: #fff;
        padding-right: 10px;
        margin-top: 0 !important;
    }

    .my-table {
        padding: 20px 10px;
    }
}
</style>

<template>
    <div class="common-table dev-table-four">
        <div class="dev-table-four__title">
            <!-- <el-date-picker v-model="date.list" type="daterange" 
                range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" @change="changeDate">
            </el-date-picker> -->
        </div>
        <div class="ts-table">
            <ts-table class="make-table" style="flex: 3" :cols="cols" hide-border :data="list" :page-param="pageParam"
                :loading="loading" hide-detail height="60vh" :cmds="cmds" @handleSizeChange="handleSizeChange"
                @handleSort="sort" @handleCurrentChange="handleCurrentChange" :hidePage="true">
            </ts-table>
        </div>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
// import variable from "@/util/variable";
export default {
    mixins: [myMixin],
    data() {
        return {
            loading: false,
            stop: true,
            pageParam: {
                pageNo: 1,
                pageSize: 20,
                total: 0,
            },
            defalutSize: 10,
            currentType: '',
            conditionList: [],
            list: [],
            cols: this.$help.generalCols([
                {
                    fieldName: 'taskTypeName',
                    name: '受理类型',
                    width: 200,
                    listQueryView: '是',
                    listQueryModel: 1,
                },
                {
                    fieldName: 'thisDay',
                    name: '今日数量',
                    width: 180,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc'
                },
                {
                    fieldName: 'thisWeek',
                    name: '本周数量',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc'
                },
                {
                    fieldName: 'thisMonth',
                    name: '本月数量',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc'
                },
                {
                    fieldName: 'thisQuarter',
                    name: '本季度数量',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc'
                    // listOrderView: '是',
                },
                {
                    fieldName: 'thisYear',
                    name: '本年度数量',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc'
                    // listOrderView: '是',
                },
                {
                    fieldName: 'growthRecentMonth',
                    name: '近一月增长比',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc'
                    // listOrderView: '是',
                },
                {
                    fieldName: 'growthRecentQuarter',
                    name: '近一季度增长比',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc'
                    // listOrderView: '是',
                },
                {
                    fieldName: 'growthRecentYear',
                    name: '近一年增长比',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc'
                    // listOrderView: '是',
                },

            ]),

            cmds: [],

            rank1: [],
            rank2: [],

            invRow: {},
            drugsNumber: 0,

            comObj: {
                value: '',
                loading: false,
                options: []
            },
            date: {
                list: [new Date(), new Date()]
            },
            typeMap: {
                newTask: '新报任务',
                govApproval: '行政审批',
                materialTask: '补充资料任务'
            }
        }
    },
    created() { },

    filters: {
        changeNum(val) {
            let str = val
            if (val == '0') {
                str = '平'
            } else if (val === '--') {
                str = '--'
            } else {
                str = (val * 100).toFixed(2) + '%'
            }
            return str
        }
    },
    methods: {
        initCustom() {
            this.pageParam.pageNo = 1
            this.getCustomList()
        },

        changeDate(date) {
            if (date) {
                this.date.list = date;
                this.initDate()
            }
        },

        getEchartData() {
            let param = {
                startDate: this.date.list[0].FormatShort(),
                endDate: this.date.list[1].FormatShort()
            }
            this.$api.get('flow/flowDevelopmentReviewProgress/findThroughEvaluationNumber', param).then(res => {
                console.log(res)
                this.$nextTick(() => {
                    this.renderBar(this.canvasId)
                })
            })
        },

        getCustomList() {
            // let startDate = this.date.list[0].FormatShort(),
            //     endDate = this.date.list[1].FormatShort();
            this.loading = true
            this.$api.get(`flow/flowDevelopmentNewTask/findStageTaskNumberAndIncrease`).then(res => {
                    let list = []
                    if (res.success) {
                        for(let key in res.data) {
                            list.push(Object.assign(res.data[key], {customType: this.typeMap[key]}))
                        }
                    }
                    this.list = list
                })
                .finally(() => {
                    this.loading = false
                })
        },

        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },

        handleCurrentChange(page) {
            this.pageParam.pageNo = page
            this.getCustomList()
        }
    },
    mounted() {
        this.initCustom()
    }
}
</script>