<template>
    <div style="height: 100%;">
        <div v-if="!$help.token.get()" class="add">
            <ts-error></ts-error>
            <!-- 暂未登录，<el-link :underline="false"  @click="$help.goLogin()">请登录！</el-link> -->
        </div>
        <el-tabs type="border-card" v-else style="height: 100%;">
            <el-tab-pane :lazy="true" label="过评查询">
                <div class="develop-trace">
                    <table-one ref="tableOne"></table-one>
                </div>
            </el-tab-pane>
            <el-tab-pane :lazy="true" label="受理查询">
                <div class="develop-trace">
                    <table-two ref="tableTwo"></table-two>
                </div>
            </el-tab-pane>
            <el-tab-pane :lazy="true" label="进度预测">
                <div class="develop-trace">
                    <table-three ref="tableThree"></table-three>
                </div>
            </el-tab-pane>
            <el-tab-pane label="任务统计">
                <div class="develop-trace">
                    <table-four></table-four>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import TableOne from './DevComponent/TsTableOne.vue'
import TableTwo from './DevComponent/TsTableTwo.vue'
import TableThree from './DevComponent/TsTableThree.vue'
import TableFour from './DevComponent/TsTableFour.vue'
export default {
    components: { TableOne, TableTwo, TableThree, TableFour },
    name: '',
    data() {
        return {
            over: { //昨日过评
                dateList: [],
            },
            accept: { //今日受理
                dateList: [],
            },
            develop: { //今日研发追踪预测
                dateList: [],
            },
            task: { //任务分类
                dateList: [],
            }
        };
    },
    methods: {

    }

}
</script>
<style lang='scss'>

.my-table {
    padding: 10px !important;
}
.develop-trace {
    height: 73vh;
    overflow-y: auto;
}

.add {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;

    .el-link--default {
        font-size: 18px;
        font-weight: 600;
    }
}
</style>